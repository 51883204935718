:root {
	--results-height: 670px;
}

h1, h2, h3, h4, nav, summary, button#start {
	font-weight: bold;
}

@media (min-width: 500px) {
	#settings[open] {
		height: 378px;
	}
}

@media (max-width: 629px) {
	nav>ul {
		flex-wrap: wrap;
	}

	nav {
		height: 88px;
	}
}

#progress[open] {
	height: 300px;
	max-height: calc((100vw - 20px) / 3 + 100px);
	height: min(300px, calc((100vw - 20px) / 3 + 100px));
}

main, footer > :not(#language-list) {
	direction: rtl;
}

sent-circle-thingy, time-circle-thingy, received-circle-thingy {
	direction: ltr;
}

button#start {
	left: 0;
	right: unset;
}